import Home from './pages/Home';
import React from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import './App.css';
import Blog from './pages/Blog';
import Profile from './pages/Profile';
import Contact from './pages/Contact';
import BlogDetail from './pages/BlogDetail';
import NotFound from './pages/NotFound';

function App() {
  return (
    <div className="app">

      <div className="brand">Dynastina Website</div>
      <nav className="nav">
        <Link to="/" className="nav-item">Home</Link>
        <Link to="/profile" className="nav-item">Profile</Link>
        <Link to="/contact" className="nav-item">Contact</Link>
        <Link to="/blog" className="nav-item">Blog</Link>
      </nav>

      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/blog" element={<Blog/>} />
        <Route path="/blog/:id" element={<BlogDetail/>} />
        <Route path="/profile" element={<Profile/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="*" element={<NotFound/>} />
      </Routes>

    </div>
  );
}

export default App;
