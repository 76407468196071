// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {SkeletonBlogDetail} from "./SkeletonBlog";

export default function BlogDetail() {
    
    const urlParams = useParams();

    const [article, setArticle] = useState([]);
    const [loading, setLoading] = useState({});
    const [notFound, setNotFound] = useState(false);

    useEffect(function () {

        document.title = "Blog Detail - Dynastina Website";

        async function getArticle (){
            const id = urlParams.id;
            const request = await fetch(`https://api.spaceflightnewsapi.net/v3/articles/${id}`);
            
            if(request.status !== 200){
                return setNotFound(true);
            }
            
            const response = await request.json();

            setArticle(response);
            setLoading(false);
        }
        getArticle();


    }, []);

    if(notFound){
        return (
            <section className="section">
                <h2 className="section-title">404 - Article Not Found :((</h2>
                <p className="section-description">Pastikan url yang anda ketik sudah benar, atau coba kembali ke halaman sebelumnya lalu refresh</p>
            </section>
        );
    }
    
    return (

        <section className="section">

            {loading ? (
                // <i>Loading articles...</i>
                <>
                    <SkeletonBlogDetail />
                </>
            ) : 
            
            <article className="article">
                <h1 className="article-title">{article.title}</h1>
                <img className="article-image" src={article.imageUrl} style={{width:'600px'}} alt={article.title}/>
                <p className="article-description">{article.summary}</p>
                <a className="article-time" href={article.url} target="_blank">Source : {article.newsSite} - {new Date(article.publishedAt).toLocaleDateString()}</a>
            </article>
            
            }

        </section>
    )
}