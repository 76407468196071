import React, { useEffect } from "react";

export default function NotFound() {

    useEffect(function(){
        document.title = "Not Found - Dynastina Website";
      });
      
    return (
        <section className="section">
            <h1 className="section-title">404 - Not Found</h1>
            <p className="section-description">Pastikan url yang anda ketik sudah benar</p>
        </section>
    )
}