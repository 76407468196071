import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SkeletonBlog from "./SkeletonBlog";

export default function Blog() {

  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {

      document.title = "Blog - Dynastina Website";

      async function getArticles() {

        const request = await fetch('https://api.spaceflightnewsapi.net/v3/articles');
        const response = await request.json();

        setArticles(response);
      }

      getArticles();
      setTimeout(() => {
        setLoading(false);
      }, 400);
  }, []);


  return (
    <section className="section">
        
        {loading && (
          <>
            {/* <i>Loading articles...</i> */}
            <SkeletonBlog />
          </>
        )}
        {!loading && (
          <div className="articles">
            <h1 className="section-title">Blog Page</h1>
            <p className="section-description">Halaman ini menjukan berbagai blog artikel</p>
            {articles.map(function(article) {
              return (
                <article key={article.id} className="article">
                  <h3 className="article-title"><Link to={`/blog/${article.id}`}>{article.title }</Link></h3>
                  <p className="article-time">
                    {article.newsSite} - {new Date(article.publishedAt).toLocaleDateString()}
                  </p>
                </article>
              )
            })}
          </div>
        )}
    </section>
  );
}
