import React from "react";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

export default function SkeletonBlog() {
    return (
            <div className="">
                <h1 className="section-title">{<Skeleton width={150} />}</h1>
                <p className="section-title">{<Skeleton />}</p>
                <section >
                    <div>
                        <h1 className="section-title">{<Skeleton  height={70} />}</h1>
                        <p className="section-description">{<Skeleton width={120}/>}</p>
                        <p className="section-description">{<Skeleton height={1}/>}</p>
                    </div>
                    <div>
                        <h1 className="section-title">{<Skeleton  height={70} />}</h1>
                        <p className="section-description">{<Skeleton width={120}/>}</p>
                        <p className="section-description">{<Skeleton height={1}/>}</p>
                    </div>
                    <div>
                        <h1 className="section-title">{<Skeleton  height={70} />}</h1>
                        <p className="section-description">{<Skeleton width={120}/>}</p>
                        <p className="section-description">{<Skeleton height={1}/>}</p>
                    </div>
                    <div>
                        <h1 className="section-title">{<Skeleton  height={70} />}</h1>
                        <p className="section-description">{<Skeleton width={120}/>}</p>
                        <p className="section-description">{<Skeleton height={1}/>}</p>
                    </div>
                    <div>
                        <h1 className="section-title">{<Skeleton  height={70} />}</h1>
                        <p className="section-description">{<Skeleton width={120}/>}</p>
                        <p className="section-description">{<Skeleton height={1}/>}</p>
                    </div>
                </section>
            </div>
    );
}

export function SkeletonBlogDetail(){
    return (
        <div className="skeleton-card">
            <section >
                <div>
                    <h1 className="section-title">{<Skeleton count={2} />}</h1>
                    <p className="section-image">{<Skeleton height={250}/>}</p>
                    <p className="section-description">{<Skeleton count={5} />}</p>
                    <p className="section-description">{<Skeleton height={1}/>}</p>
                </div>
            </section>
        </div>
    );
}

export function SkeletonHome(){
    return (
        <div >
            <h1 className="section-title">{<Skeleton width={150}/>}</h1>
            <p className="section-title">{<Skeleton count={2} />}</p>
        </div>
    );
}

export function SkeletonProfile(){
    return (
        <div >
            <h1 className="section-title">{<Skeleton width={150}/>}</h1>
            <h1 className="section-title">{<Skeleton height={300}/>}</h1>
            <p className="section-title">{<Skeleton count={3} />}</p>
        </div>
    );
}

export function SkeletonContact(){
    return (
        <div >
            <h1 className="section-title">{<Skeleton width={150}/>}</h1>
            <p className="section-title">{<Skeleton count={2} />}</p>
            <ul>
                <li>{<Skeleton />}</li>
                <li>{<Skeleton />}</li>
                <li>{<Skeleton />}</li>
                <li>{<Skeleton />}</li>
                <li>{<Skeleton />}</li>
            </ul>
        </div>
    );
}