import React, { useEffect, useState } from "react";
import { SkeletonHome } from "./SkeletonBlog";

export default function Home() {
  
  const [loading, setLoading] = useState(true);

  useEffect(function(){
    document.title = "Home - Dynastina Website";

    setTimeout(() => {
      setLoading(false);
    }, 400);
  });
  
  return (
    <section className="section">

        {loading ? 
          (
            <SkeletonHome />
          )
        :
            (
              <>
                <h1 className="section-title">Home Page</h1>
                <p className="section-description">Selamat datang! disini anda akan melihat banyak hal keren </p>
              </>
            )
        }
    </section>
  );
}