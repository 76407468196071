import React, { useEffect, useState } from "react";
import { SkeletonContact } from "./SkeletonBlog";

export default function Contact() {

  const [loading, setLoading] = useState(true);
  
  useEffect(function(){
    document.title = "Contact - Dynastina Website";

    setTimeout(() => {
      setLoading(false);
    }, 400);
  });

  return (
    <section className="section">

      {loading ? 
          (
            <SkeletonContact />
          )
        :
            (
              <>
                <h1 className="section-title">Contact Page</h1>
                <p className="section-description">Kamu dapat menghubungi saya melalui informasi dibawah ini :</p>
                <ul>
                  <li><a href="https://wa.me/+628581380262890" target="_blank" className="link-contact">Whatsapp</a></li>
                  <li><a href="https://www.instagram.com/dynastina_/" target="_blank" className="link-contact">Instagram</a></li>
                  <li><a href="https://m.facebook.com/akunbackup.akunbackup.12" target="_blank" className="link-contact">Facebook</a></li>
                  <li><a href="https://www.linkedin.com/in/ganden-dynastina/" target="_blank" className="link-contact">Linkedin</a></li>
                  <li><a href="https://dynastina.github.io/" target="_blank" className="link-contact">Github</a></li>
                  <li><a href="https://www.youtube.com/watch?v=dQw4w9WgXcQ" target="_blank" className="link-contact">!!!!---&nbsp;&nbsp;&nbsp;Bonus&nbsp;&nbsp;&nbsp;---!!!!</a></li>
                </ul>
              </>
            )
        }
    </section>
  );
}
