import React, { useEffect, useState } from "react";
import { SkeletonProfile } from "./SkeletonBlog";
import image from "../images/ge.jpg"

export default function Profile() {

  const [loading, setLoading] = useState(true);

  useEffect(function(){
    document.title = "Profile - Dynastina Website";

    setTimeout(() => {
      setLoading(false);
    }, 400);
  });

  
  return (
    <section className="section">

        {loading ? 
          (
            <SkeletonProfile />
          )
        :
            (
              <>
                <h1 className="section-title">Profile Page</h1>
                <img src={image} width={`100%`}/>
                <p className="section-description">Kelahiran 2003, ENFJ, Taurus, Suka warna item, melihara semut, programmer, goldar #O dan saya tuh orang nya asksksksksksk banget</p>
              </>
            )
        }
    </section>
  );
}
